import * as React from "react"
import { graphql, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import {
  PageHeader,
  Section,
  Content,
  Row,
  Col,
} from "../styles/StyledElements"
import Layout from "../components/layout"
import Seo from "../components/seo"

import { processCompanies } from "../components/viz/processCompanies"
//import { LayoutForceMap } from "../components/viz/layoutForceMap"
import { DataSummary } from "../components/viz/dataSummary"
import { CompanyIndex } from "../components/viz/companyIndex"

export default function Reveiw({ data }) {
  const title = "Data Reveiw"
  // let dataset = processCompanies(data)

  return (
    <Layout>
      <Seo title={title} />
      <PageHeader>
        <Content>
          <Row>
            <Col>
              <h1>{title}</h1>
            </Col>
          </Row>
        </Content>
      </PageHeader>
      <Section>
        <Content>
          <Row>
            <Col>{/* <DataSummary dataset={dataset} /> */}</Col>
          </Row>
        </Content>
      </Section>
      <Section>{/* <CompanyIndex nodes={data.items.nodes} /> */}</Section>
    </Layout>
  )
}

//export default IndexPage

export const query = graphql`
  {
    items: allAirtable(filter: { table: { eq: "Organization List" } }) {
      nodes {
        recordId
        data {
          Company
          Location
          Year_Founded
          Year_Acquired_or_Dissolved
          Dissolution_Type
          Number_of_Employees
          Employees
          Latitude
          Longitude
          Total_Investment
          Categories {
            recordId
            data {
              Name
              Name_Short
            }
          }
          Subcategories {
            recordId
            data {
              Name
              Name_Short
            }
          }
        }
      }
    }
    categories: allAirtable(filter: { table: { eq: "Categories" } }) {
      nodes {
        recordId
        data {
          Name
          Name_Short
          Order
          Description {
            childMarkdownRemark {
              html
            }
          }
          Subcategories {
            recordId
            data {
              Name
              Name_Short
              Description {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
      }
    }
    markdown: allMarkdownRemark(
      filter: { frontmatter: { section: { in: ["intro", "descriptions"] } } }
    ) {
      nodes {
        html
        frontmatter {
          ref
          section
          title
        }
      }
    }
  }
`
