import * as React from "react"
import * as d3 from "d3"
import styled from "styled-components"
import { Link } from "gatsby"

import { Content, Row, Col } from "../../styles/StyledElements"

const { slugFormat } = require("../../utilities/slugFormat")

const CompanyTag = styled.div`
  display: inline-block;
  padding: 0px 6px;
  margin-right: 4px;
  margin-bottom: 4px;
  border-radius: 4px;
  background: #ddd;
`

export function CompanyIndex({ nodes }) {
  const title = "Index"
  let companies = nodes.sort((a, b) =>
    d3.descending(b.data.Company, a.data.Company)
  )
  return (
    <Content>
      <Row>
        <Col>
          <h2>Enterprise Index ({companies.length})</h2>
          <div>
            {companies.map(company => (
              <CompanyTag key={company.recordId}>
                {company.data.Company}
              </CompanyTag>
            ))}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Link to="/">Go back to the homepage</Link>
        </Col>
      </Row>
    </Content>
  )
}
