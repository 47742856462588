import React from "react"
// import PropTypes from "prop-types"
import * as d3 from "d3"
import styled from "styled-components"
//import { below } from "../styles/utilities/breakpoints"
import { processSummary } from "./processCompanies"
import dataDict from "../../content/data/data-dictionary.json"
import { summarizeNodeTaxonomy } from "./dataUtilities"

import { ListTagsCounted } from "../listTags"

const Summary = styled.div`
  padding: 1rem 0;
`

export const DataSummary = ({ dataset }) => {
  console.log(dataset)
  let summaryData = processSummary(dataset.set)
  console.log(summaryData)

  let categories = summarizeNodeTaxonomy(dataset.set, "categoriesArray")
  let subcategories = summarizeNodeTaxonomy(dataset.set, "subcategoriesArray")

  let locationSummary = d3
    .groups(dataset.set, d => d.location)
    .map(d => {
      return {
        value: d[0],
        label: d[0],
        count: d[1].length,
      }
    })
    .sort((a, b) => d3.descending(a.count, b.count))

  let sizeSummary = d3
    .groups(dataset.set, d => d.size)
    .map(d => {
      return {
        value: d[0],
        label: d[0],
        count: d[1].length,
      }
    })
    .sort((a, b) => d3.descending(a.count, b.count))

  return (
    <Summary>
      <h2>Enterprises: {summaryData.count}</h2>
      <KeyStatus data={summaryData} dataKey={"investment"} />
      <KeyStatus data={summaryData} dataKey={"employees"} />
      <KeyStatus data={summaryData} dataKey={"founded"} />
      <KeyStatus data={summaryData} dataKey={"size"} />
      <ListTagsCounted array={sizeSummary} type={"size"} />
      <KeyStatus data={summaryData} dataKey={"category"} />
      <ListTagsCounted array={categories} type={"category"} />
      <KeyStatus data={summaryData} dataKey={"subcategory"} />
      <ListTagsCounted array={subcategories} type={"subcategory"} />
      <KeyStatus data={summaryData} dataKey={"location"} />
      <ListTagsCounted array={locationSummary} type={"location"} />
      <KeyStatus data={summaryData} dataKey={"geocoded"} />
    </Summary>
  )
}

const Profile = styled.div`
  h3 {
    margin-bottom: 0;
  }
  .missing {
    font-size: 0.7rem;
  }
`

export const KeyStatus = ({ data, dataKey }) => {
  //console.log(data)
  //console.log(dataKey)
  let valueFields = ["investment", "employees"]

  let missing = data.missing[dataKey]
  let completed = data.count - missing.length
  let completion = formatPercent(completed / data.count)
  return (
    <Profile>
      <div>
        <h3>{dataDict[dataKey].label}</h3>
      </div>

      <div>Progress: {`${completed}/${data.count} (${completion})`}</div>
      {valueFields.includes(dataKey) && (
        <div>
          Total: {d3.format(dataDict[dataKey].format)(data.total[dataKey])}
        </div>
      )}
      {missing.length > 0 && (
        <div className={"missing"}>
          Missing:{" "}
          {missing
            .map(d => d.company)
            .sort(d3.ascending)
            .join(", ")}
        </div>
      )}
    </Profile>
  )
}

const formatPercent = d3.format(".0%")
